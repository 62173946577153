import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import HeroSection from '../components/sections/eventShow/heroSection';
import VillageLinkSection from '../components/sections/eventShow/villageLinkSection';
import DescriptionSection from '../components/sections/eventShow/descriptionSection';
import OrganizerSection from '../components/sections/eventShow/organizerSection';
import BookingSection from '../components/sections/eventShow/bookingSection';
import OpeningHours from '../components/sections/eventShow/openingHours';
import InformationSection from '../components/sections/eventShow/informationSection';
import {flatMap } from 'lodash';

const EventShow = ({ data }) => {
  const event = data.sanityEvent;
  const scolaires = event.education;
  const audiences = data.audiences.nodes;
  // if there are event.timeSlots, we override the village time slots
  // because some events do not match the standard village time slots
  const timeSlotsPerLocation = !event.timeSlots.length && event.village.length ? event.village.reduce((acc, village) => {
    acc[village.title] = village.timeSlots.filter(timeSlot => scolaires ? timeSlot.isEducation : !timeSlot.isEducation );
    return acc;
  }, {}) : { "address": event.timeSlots};
  const timeSlots = flatMap(timeSlotsPerLocation, (timeSlots) => timeSlots);
  return (
    <Layout>
      <SEO title={event.title} description={event.description} />
      <HeroSection event={event} scolaires={scolaires} audiences={audiences} bookingRequired={event.bookingRequired} bookingRecommanded={event.bookingRecommanded}/>
      {event.village.length ? <VillageLinkSection event={event} /> : null}
      <DescriptionSection
        audienceCustom={event.audienceCustom}
        description={event._rawDescription}
        scolaires={scolaires}
      />
      {event.projectOwners.length > 0 && (
        <OrganizerSection
          organizers={event.projectOwners}
          scolaires={scolaires}
        />
      )}
      {timeSlots.length > 0 && (
        <OpeningHours
          timeSlotsPerLocation={timeSlotsPerLocation}
          scolaires={scolaires}
          eventCanceled={event.eventCanceled}
        />
      )}
      {(event.bookingRequired) && !event.eventCanceled && (
        <BookingSection
          bookingPhone={event.bookingPhone}
          bookingEmail={event.bookingEmail}
          bookingWebsite={event.bookingWebsite}
          scolaires={scolaires}
          bookingText="Attention, cet événement est uniquement accessible sur réservation!"
        />
      )}
      {event.bookingRecommanded && !event.eventCanceled && (
        <BookingSection
          bookingPhone={event.bookingPhone}
          bookingEmail={event.bookingEmail}
          bookingWebsite={event.bookingWebsite}
          scolaires={scolaires}
          bookingText="Attention, il est recommandé de réserver à l'avance pour cet événement!"
        />
      )}
      <InformationSection
        informationPhone={event.informationPhone}
        informationEmail={event.informationEmail}
        shouldBook={event.bookingRequired || event.bookingRecommanded}
      />
    </Layout>
  );
};

export default EventShow;

export const query = graphql`
  query($eventId: String!) {
    audiences: allSanityAudience {
      nodes {
        id
        name
      }
    }
    sanityEvent(id: { eq: $eventId }) {
      title
      department {
        name
      }
      audience {
        id
        name
      }
      audienceCustom {
        from
        to
      }
      address
      featured
      eventCanceled
      village {
        id
        title
        address
        timeSlots {
          endDate
          endTime
          startDate
          startTime
          isEducation
        }
        slug {
          current
        }
        department {
          name
        }
      }
      education
      _rawDescription
      bookingRequired
      bookingRecommanded
      bookingPhone
      bookingEmail
      bookingWebsite
      informationEmail
      informationPhone
      timeSlots {
        endDate
        endTime
        startDate
        startTime
      }
      timeSlotsPhoneContact
      projectOwners {
        id
        name
        description
        website
        facebook
      }
      format {
        name
        formatIcon {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
      theme {
        name
        id
      }
      image {
        asset {
          gatsbyImageData(width: 1200)
        }
        hotspot {
          x
          y
        }
      }
    }
  }
`;
