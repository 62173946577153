import React from 'react';
import InfoCard from './infoCard';
import SectionWrapper from '../../layout/sectionWrapper';
import SectionContainer from '../../layout/sectionContainer';

const getRenseignements = (informationEmail, informationPhone) => {
  if (!informationEmail && !informationPhone) {
    return null;
  }
  if (informationEmail) {
    return <a className="underline" href={`mailto:${informationEmail}`}>{informationEmail}</a>
  } else {
    return <a className="underline" href={`tel:${informationPhone}`}>{informationPhone}</a>
  }
}

const RenseignementsSection = ({ informationPhone, informationEmail, shouldBook }) => {
  const renseignements = getRenseignements(informationEmail, informationPhone);
  return (
    <SectionWrapper>
      <SectionContainer customClasses="pb-12" id="opening-hours">
        <InfoCard title="Renseignements" customClasses="">
          <div className="flex justify-center text-base leading-relaxed sm:text-lg">
            {renseignements ? <span>Pour tous renseignements, contactez l'organisateur : {renseignements}</span> : "Contact non disponible pour le moment."}
          </div>
          {shouldBook && <div className="flex justify-center text-base leading-relaxed sm:text-lg">
            <span>Attention, pour réserver il est préférable d'utiliser le contact dédié, si différent de celui-ci.</span>
            </div>}
        </InfoCard>
      </SectionContainer>
    </SectionWrapper>
  );
}

export default RenseignementsSection;