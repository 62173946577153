import React, {useState, useEffect} from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import styled from 'styled-components';
import {MdToday} from 'react-icons/md';
import {FaMapMarkerAlt, FaBan} from 'react-icons/fa';
import {IoIosPeople} from 'react-icons/io';
import CustomGatsbyImage from '../../global/customGatsbyImage';
import SectionWrapper from '../../layout/sectionWrapper';
import SectionContainer from '../../layout/sectionContainer';
import FeaturedLabel from '../../global/featuredLabel';
import {processDate} from '../../../utils/processDate';
import {capitalize} from '../../../utils/helpers';
import Address from './Address';

const HeroCard = styled.div`
  [data-name='image'] {
    height: 22.5em;

    @media (max-width: 768px) {
      height: 14em;
    }
  }

  [data-name='grid-info'] {
    grid-template-columns: 70% 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 2.5em;
    grid-column-gap: 1em;
  }
`;

const BookingButton = ({
  isBookingNeeded,
  isBookingRecommanded,
  isMobile = false,
  scolaires,
  eventCanceled,
}) => {
  if (eventCanceled) {
    return (
      <span className="inline-flex rounded-md shadow-sm">
        <div
          className={`inline-flex items-center px-4 py-2 space-x-2 text-base font-bold leading-6 uppercase transition duration-150 ease-in-out rounded-full border-4 border-red-600 ${isMobile ? 'text-white' : 'text-red-600'
            }`}
        >
          <span>
            <FaBan />
          </span>
          <span>événement annulé</span>
        </div>
      </span>
    );
  } else if (isBookingNeeded) {
    return (
      <a
        href="#reservation"
        className="inline-flex transform rounded-md hover:scale-105"
      >
        <button
          type="button"
          className={` inline-flex items-center text-base px-4 font-bold leading-6 text-orange-900 uppercase transition duration-150 ease-in-out border border-transparent rounded-full bg-secondary focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-secondary space-x-2 ${isMobile ? 'py-3' : 'py-2'
            }`}
        >
          <span className="text-xl">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
          </span>
          <span>réserver maintenant</span>
        </button>
      </a>
    );
  } else if (isBookingRecommanded) {
    return (
      <a
        href="#reservation"
        className="inline-flex transform rounded-md hover:scale-105"
      >
        <button
          type="button"
          className={`text-start inline-flex items-center text-base px-4 font-bold leading-6 text-orange-900 uppercase transition duration-150 ease-in-out border border-transparent rounded-full bg-secondary focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-secondary space-x-2 ${isMobile ? 'py-3' : 'py-2'
            }`}
        >
          <span className="text-xl">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="none"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
          </span>
          <span className="text-left">réservation recommandée</span>
        </button>
      </a>
    );
  } else {
    return (
      <span className="inline-flex rounded-md">
        <div
          className={`inline-flex items-center px-4 py-2 text-base font-bold leading-6 text-gray-700 uppercase transition duration-150 ease-in-out border border-transparent rounded-full ${scolaires ? 'bg-edu' : 'bg-primary'
            }`}
        >
          entrée libre
        </div>
      </span>
    );
  }
};

const HeroSection = ({audiences, event, scolaires}) => {
  const {
    title,
    audience,
    audienceCustom,
    theme,
    format,
    bookingRequired,
    bookingRecommanded,
    image,
    featured,
    eventCanceled,
    timeSlotsPhoneContact,
  } = event;


  const getBackgroundColor = () => {
    if (eventCanceled) {
      return 'bg-red-600';
    } else if (bookingRequired || bookingRecommanded) {
      return 'bg-secondary';
    } else {
      return 'bg-primary';
    }
  };
  const getFormattedAudience = (audienceCustom) => {
    if (!audienceCustom) return null;
    const {from, to} = audienceCustom
    const realTo = to > 25 ? null : to;
    if (from && realTo) {
      return `De ${from} à ${realTo} ans`
    } else if (from) {
      return `Dès ${from} ans`
    } else {
      return `Jusqu'à ${realTo} ans`
    }
  }
  const [displayAudience, setDisplayAudience] = useState(null);
  useEffect(() => {
    if (audience.length > 0) {
      const audienceIncludesOthers = audience.some(
        audienceData => audienceData.name === 'Autre')
      const formattedAudience = audience.length === audiences.length || (audience.length === audiences.length - 1 && !audienceIncludesOthers) ? "Tous publics" : audience.map(audienceData => capitalize(audienceData.name)).join(', ');
      setDisplayAudience(formattedAudience);
    } else if (audienceCustom) {
      const value = getFormattedAudience(audienceCustom)
      setDisplayAudience(value);
    } else {
      setDisplayAudience(null);
    }
  }, []);
  const addresses = event.address ? [event.address] : event.village.map(village => village.address);
  const timeSlots = event.village.length > 0 ? event.village.flatMap(village => village.timeSlots) : event.timeSlots;
  const getNotTimeSlotsMessage = (timeSlots, timeSlotsPhoneContact) => {
    if (timeSlots.length === 0 && !timeSlotsPhoneContact) {
      return <span>'Les horaires ne sont pas encore disponibles.'</span>;
    } else if (timeSlotsPhoneContact) {
      return <span>Veuillez contacter l'organisateur au <a className="underline" href={`tel:${timeSlotsPhoneContact}`}>{timeSlotsPhoneContact}</a> pour obtenir les dates et horaires.</span>;
    } else {
      return null;
    }
  }
  const notTimeSlotsMessage = getNotTimeSlotsMessage(timeSlots, timeSlotsPhoneContact);
  return (
    <SectionWrapper>
      <SectionContainer customClasses="pt-0 md:pt-20 pb-12">
        <HeroCard className="mx-auto overflow-hidden bg-white rounded-lg shadow md:max-w-5xl">
          <div data-name="image" className="relative bg-sectionBackground">
            {image && (
              <CustomGatsbyImage image={image} customClasses="h-full" />
            )}
            <div className="absolute flex space-x-2 top-5 left-4 md:left-10">
              {featured && <FeaturedLabel customClasses="text-base" />}
            </div>
          </div>
          <div
            data-name="grid-info"
            className="px-4 py-5 text-gray-500 sm:py-6 sm:px-10 md:grid"
          >
            <div className="col-span-1 row-span-1 text-center md:text-left">
              <h1 className="text-2xl font-bold text-gray-700 uppercase sm:text-3xl md:text-3xl">
                {title}
              </h1>
              <div className="flex flex-col text-base font-hairline text-purple-400 md:text-lg">
                {theme.map((theme) => <span key={theme.id}>- {theme.name}</span>)}

              </div>
            </div>
            <div className="flex flex-col col-span-1 row-start-2 mt-8 space-y-4 md:mt-0">
              {/* ADDRESS & MAP */}
              <div
                className="flex gap-2"
              >
                <span className="text-lg">
                  <FaMapMarkerAlt />
                </span>
                <div className="flex flex-col gap-2">{addresses.map((address) => <Address key={address} address={address} />)}</div>
              </div>
              {/* TIMESLOTS */}
              <div className="flex items-center space-x-2">
                <span className="text-xl">
                  <MdToday />
                </span>
                {timeSlots.length > 1 && (
                  <a href="#opening-hours" className="underline">
                    <p>Voir tous les horaires</p>
                  </a>
                )}
                {timeSlots.length === 1 && (
                  <div>
                    <p>{processDate(timeSlots[0])}</p>
                  </div>
                )}
                <div className={!notTimeSlotsMessage ? 'hidden' : ''}>{notTimeSlotsMessage}</div>
              </div>
            </div>

            <div className="items-start hidden col-span-1 col-start-2 md:flex">
              <BookingButton
                isBookingNeeded={bookingRequired}
                isBookingRecommanded={bookingRecommanded}
                scolaires={scolaires}
                eventCanceled={eventCanceled}
              />
            </div>
            <div className="flex-col col-span-1 col-start-2 mt-6 space-y-3 md:space-y-2 md:mt-0">
              {/* FORMAT */}
              {format[0] && <div className="flex items-center space-x-2">
                {format[0]?.formatIcon?.asset && <span
                  className={`h-8 w-8 text-2xl text-white rounded-full p-2 flex items-center justify-center ${scolaires ? 'bg-eduDark' : 'bg-primary'
                    }`}
                >
                  <GatsbyImage
                    image={format[0].formatIcon.asset.gatsbyImageData}
                    className="w-full" alt="" />
                </span>}
                <span>{format[0].name}</span>
              </div>}
              {/* AUDIENCE */}
              <div className="flex items-center space-x-2">
                <span
                  className={`h-8 w-8 p-1 flex items-center justify-center text-2xl text-white rounded-full ${scolaires ? 'bg-eduDark' : 'bg-primary'
                    }`}
                >
                  <IoIosPeople />
                </span>
                {displayAudience && <span>{displayAudience}</span>}
                {!displayAudience && <span>Tout Public</span>}
              </div>
            </div>
          </div>
          <div
            className={`flex items-center justify-center w-full mt-6 md:hidden ${getBackgroundColor()}`}
          >
            <BookingButton
              isBookingNeeded={bookingRequired}
              isMobile={true}
              eventCanceled={eventCanceled}
            />
          </div>
        </HeroCard>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default HeroSection;
