import React from 'react';
import { Link } from 'gatsby';
import { FaCampground } from 'react-icons/fa';
import SectionWrapper from '../../layout/sectionWrapper';
import SectionContainer from '../../layout/sectionContainer';
import { formatDepartmentName } from '../../../utils/formatDepartmentName';

const VillageLinkSection = ({ event }) => {
  const getVillageLinks = (villages) => {
    const links = villages.map((village) => {
      const department = formatDepartmentName(village.department.name);
      return (
        <Link
          key={village.slug.current}
          to={`/${department}/${village.slug.current}`}
          className="text-sm border-b-2 border-gray-500 w-fit sm:text-base hover:border-secondary"
        >
          {village.title}
        </Link>
      );
    });
    return links;
  }
  return (
    <SectionWrapper>
      <SectionContainer customClasses="pb-12 -mt-6">
        <div
          className="relative flex max-w-5xl mx-auto bg-white rounded-lg shadow"
        >
          <div className="flex px-4 py-5 space-x-2 text-gray-500 sm:py-6 sm:px-10">
            <span className="text-xl">
              <FaCampground />
            </span>
            <span className="flex flex-shrink-0 space-x-1 ">
              <span className="hidden md:inline-block">
                {`Cet événement sera présent sur : `}
              </span>
            </span>
            <div className="flex flex-wrap flex-shrink gap-4">
                {getVillageLinks(event.village)}
              </div>
          </div>
        </div>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default VillageLinkSection;
