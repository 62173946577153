import React from "react";

const Address = ({address}) => (
  <span>
    {address} (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="inline w-3 h-3 mr-1">
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
    </svg>
    <a className="underline" href={`https://www.google.com/maps/search/?api=1&query=${address}`} target='_blank' rel="noopener noreferrer">carte</a>)
  </span>
);

export default Address;